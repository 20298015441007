<template>
  <article
    class="message"
    :class="combindedClass"
  >
    <div class="message-body">
      {{ message }}
      <slot></slot>
    </div>
  </article>
</template>

<script>
export default {
  props: {
    /**
     * For what'll be shown inside of the message box.
     */
    message: {
      type: String,
      default: '',
    },

    size: {
      type: String,
      default: '',
    },

    /**
     * type bepaalt het type weergave
     * eq. is-info = for information style. It defines the style of the message
     */
    type: {
      type: String,
      default: 'is-link',
    },
  },
  data() {
    return {}
  },

  computed: {
    combindedClass() {
      let c = this.type

      if (this.size.length > 0) {
        c = c + ' ' + this.size
      }

      return c
    },
  },
}
</script>

<style lang="scss" scoped></style>
