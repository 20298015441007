import axios from 'axios'
import store from '../store'


export default {
  methods: {
    async getAllEventTypes() {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/global/eventtypes`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY
          }
        }
      )
      return response
    },

    async getLocationEventTypes(locationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/global/eventtypes/location/${locationId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY
          }
        }
      )
      return response
    },

  }
}