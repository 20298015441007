<template>
  <ui-component-modal
    :modalTitle="$t('Components.Spaces.SpaceEventTypes.ModalMain_Title')"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="saveSpace"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <Message :message="$t('Components.Spaces.SpaceEventTypes.Message_WhatKindOfEvents')" />
      <div class="columns is-multiline is-gapless">
        <div
          class="column is-half"
          v-for="(eventType, index) in allEventTypes"
          :key="index"
        >
          <ui-base-checkbox
            :label="eventType.Name"
            v-model="mSpace.EventTypes"
            :checkValue="eventType.Id"
          />
        </div>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import globalProvider from '@/providers/global'
import spaceProvider from '@/providers/space'
import Message from '@/components/UI/Message'

export default {
  name: 'modal-edit-space-event-types',

  components: {
    Message
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      allEventTypes: [],
      locationId: Number(this.$route.params.locationId),
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      mSPace: null,
    }
  },

  computed: {
    ...mapState('spaceStore', ['space']),
  },

  created() {
    this.mSpace = JSON.parse(JSON.stringify(this.space))

    this.getLocationEventTypes()
  },

  methods: {
    ...mapMutations('spaceStore', ['setSpace']),

    getLocationEventTypes() {
      globalProvider.methods
        .getLocationEventTypes(this.locationId)
        .then((response) => {
          if (response.status === 200) {
            this.allEventTypes = response.data
          }
        })
    },

    saveSpace() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true

        spaceProvider.methods
          .updateSpace(self.mSpace)
          .then((response) => {
            if (response.status === 200) {
              self.setSpace(response.data)

              setTimeout(() => {
                self.$emit('spaceMeetingtypeEdited', self.mSpace)
                self.onClickCancel()
              }, 1500)
            }
          })
          .catch((error) => {
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
            self.isSavingSuccess = true
          })
      }
    },
  },
}
</script>
